.mobileMixArchiveContainer{
    padding: 0px;
    padding-bottom: 20px;
    padding-left: 5px;
}

.mobileMixArchiveButton{
    text-align: left;
    font-family: "ABC Medium";
    font-size: 4vw;
    background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
    padding: 0px;
    padding-top: 4vw;
    color: #242424;
}

.mobileMixArchives{
    padding: 0px;
    padding-top: 10px;
    text-align: left;
    font-family: "ABC Medium";
    font-size: 3.5vw;
    background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
    color: #242424;
}