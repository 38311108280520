.transport{
    position: absolute;
    width: 100%;
    bottom: 0px;
    display:inline-block;
}

.currentMix{
    font-family: "ABC Medium";
    font-size: 20px;
    padding: 3px;
    color: #242424;
    position: absolute;
    left: 60px;
    bottom: 52px;
    cursor: default;
}

.currentDuration{
    font-family: "ABC Medium";
    font-size: 20px;
    position: absolute;
    left: 60px;
    bottom: 29px;
    color: #242424;
    cursor: default;
}

.blackLine {
    position: absolute;
    width: 3px;
    height: 45px;
    left: 160px;
    bottom: 33px;
    transition-property: background-color;
    transition-duration: 0.5s;
}

.currentTime{
    font-family: "ABC Medium";
    color: #242424;
    font-size: 40px;
    padding: 0px;
    position: absolute;
    top: -50px;
    cursor: default;
}

.playPause {
    position: absolute;
    color: #242424;
    left: 30px;
    bottom: 60px;
    font-size: 20px;
    transition-property: color;
    transition-duration: 0.5s;
    cursor: pointer;
}

.currentInfo{
    position: absolute;
    bottom: 32px;
    width: 300px;
    height: 47px;
}

.currentSong{
    font-family: "ABC Medium";
    color: #242424;
    font-size: 20px;
    padding: 0px;
    margin: 0px;
    line-height: 20px;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: default;
}

.currentArtist{
    font-family: "ABC Medium";
    color: #242424;
    font-size: 20px;
    padding: 0px;
    margin: 0px;
    line-height: 20px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    cursor: default;
}

@font-face {
    font-family: "Founders Semibold";
    src: local("FoundersGroteskCondensed-Semibold.otf"),
    url("../../../fonts/FoundersGroteskCondensed-Semibold.otf") format("truetype");
}

@font-face {
    font-family: "ABC Medium";
    src: local("ABCFavoritMonoEdu-Medium."),
    url("../../../fonts/ABCFavoritMonoEdu-Medium.otf") format("truetype");
}