.mobileSelectedMix {
    font-family: "Founders Semibold";
    font-size: 16vw;
    color: #242424;
    padding: 0px;
    padding-left: 5px;
}

.mobileSelectedMixContainer {
    display: flex;

    padding: 0px;
}

.mobilePlayButton{
    font-family: "ABC Medium";
    background: none;
    color: #242424;
	border: none;
    margin: 0px;
    padding: 0px;
    padding-top: 1.2vw;
}

