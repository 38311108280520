.main {
    display: flex;
    position: absolute;
    top:60%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 30px;
    z-index: -1;
    opacity: 0;
}

.visible{
  opacity: 1;
  transition:   opacity .2s;
}

.hidden {
  opacity: 0;
  transition: opacity .2s;
}

.container1 {
    height: 50%;
    padding-right: 6vw;
    width: 18vw;
}

.container2 {
    height: 50%;
    padding-right: 10vw;
    width: 18vw;
}

.song{
    font-family: "ABC Medium";
    font-size: 1.3vw;
    margin: 0px;
    padding: 0;
    line-height: 1.3vw;
    color: #242424;
}

.artist{
    color: #242424;
    font-size: 1.1vw;
    padding: 0;
    font-family: "ABC Medium";
    color: #242424;
    padding-bottom: 1vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


@font-face {
    font-family: "Founders Semibold";
    src: local("FoundersGroteskCondensed-Semibold.otf"),
    url("../../../fonts/FoundersGroteskCondensed-Semibold.otf") format("truetype");
}

@font-face {
    font-family: "ABC Medium";
    src: local("ABCFavoritMonoEdu-Medium."),
    url("../../../fonts/ABCFavoritMonoEdu-Medium.otf") format("truetype");
}