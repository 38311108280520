.topTitle{
    font-family: "Founders Semibold";
    font-size: 3vw;
    padding: 10px;
    padding-left: 40px;
    cursor: default;
}



@font-face {
    font-family: "Founders Semibold";
    src: local("FoundersGroteskCondensed-Semibold.otf"),
    url("../../../fonts/FoundersGroteskCondensed-Semibold.otf") format("truetype");
}