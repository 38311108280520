.mobileTracklist {
    padding-left: 5px;
    width: 100%;
}

.mobileSong {
    font-family: "ABC Medium";
    font-size: 3.3vw;
    margin: 0px;
    padding: 0;
    line-height: 3.5vw;
    padding-top: 10px;
    padding-right: 4vw;
    color: #242424;
}

.mobileArtist {
    font-size: 2.5vw;
    padding: 0;
    font-family: "ABC Medium";
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #242424;
}

.mobileTracklistColumn
{
    width: 47%;
}

.mobileTracklistColumns
{
    display: flex;
}

.mobileTracklistButton {
    font-family: "ABC Medium";
    font-size: 4vw;
	color: inherit;
	border: none;
	cursor: pointer;
    padding: 0px;
    padding-top: 4vw;
    align-items: left;
    text-align: left;
    color: #242424;
    margin: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0);
}
