.mixArchiveContainer{
    position: absolute;
    right: 260px;
    top: 20px;
    text-align: right;
}

.mixArchiveButton{
    font-family: "ABC Medium";
    font-size: 26px;
    background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
    padding-bottom: 20px;
    color: #242424;
}

.mixArchives{
    text-align: right;
    font-family: "ABC Medium";
    font-size: 1vw;
    background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
    color: #242424;
}

.mixText{
    padding-top: 40px;
}

@font-face {
    font-family: "Founders Semibold";
    src: local("FoundersGroteskCondensed-Semibold.otf"),
    url("../../../fonts/FoundersGroteskCondensed-Semibold.otf") format("truetype");
}

@font-face {
    font-family: "ABC Medium";
    src: local("ABCFavoritMonoEdu-Medium."),
    url("../../../fonts/ABCFavoritMonoEdu-Medium.otf") format("truetype");
}