/* * {background-color: #cecece} */
.backgroundImage {
    position: absolute;
    /* width: 100%; */
    /* height: 100%; */
    margin: auto;
    background-size: cover;
    z-index: -2;
    background-position: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* filter: brightness(80%); */
    overflow: hidden;
    transition-property: background-color;
    transition-duration: 0.5s;
}

.transitionText{
    transition-property: color;
    transition-duration: 0.5s;
}

.backgroundImage::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(10px); */
  pointer-events: none; /* make the overlay click-through */
  /* animation-name: desktop; */
}

/* @keyframes desktop {
  0% {backdrop-filter: blur(10px) } 
  50% {backdrop-filter: blur(10px) } 
  80% {backdrop-filter: blur(0px) }
} */

