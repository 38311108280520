/* html{
    padding: 0px;
    margin: 0px ;
} */

.sourcesradio {
    display: inline-block;
    font-size:50px;
    line-height: -4px;
    height: 0px;
    color: #F1F1F1;
    font-family: "Founders Semibold";
    padding: 0px;
    align-items: center;
    text-align: center;
    margin: auto;
    background-color: #1b1b1b;
}

.bottomrow{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.center {
   position: absolute;
   top: 49%; 
   left: 50%;
   transform: translate(-50%, -55%);
   width:300px;
   background-color: #1b1b1b;
   text-align: center;
}

@keyframes example {
  0% {opacity: 100%; z-index: 1;}
  85% {opacity: 100%; z-index: 1;}
  99% {opacity: 0%; z-index: 1;}
  100% {opacity: 0%; z-index: -1;}
}

.landingBackground {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    top: 0px;
    background-color: #1b1b1b;
    mix-blend-mode: normal;
    position: absolute;
    color: #f1f1f1;
    opacity: 0%;
    z-index: -10;
    animation-name: example;
    animation-duration: 1.5s;
}

@font-face {
    font-family: "ABC Medium";
    src: local("ABCFavoritMonoEdu-Medium."),
    url("../fonts/ABCFavoritMonoEdu-Medium.otf") format("truetype");
}

@font-face {
    font-family: "Founders Semibold";
    src: local("FoundersGroteskCondensed-Semibold.otf"),
    url("../fonts/FoundersGroteskCondensed-Semibold.otf") format("truetype");
}