.aboutContainer{
    position: absolute;
    right: 120px;
    top: 20px;
    text-align: right;
}

.aboutButton{
    font-family: "ABC Medium";
    font-size: 26px;
    background: none;
	color: inherit;
	border: none;
	cursor: pointer;
	outline: inherit;
    padding-bottom: 10px;
    color: #242424;
}

.aboutText{
    text-align: right;
    font-family: "ABC Medium";
    font-size: 1vw;
    line-height: 3px;
    color: #242424;
    z-index: 1;
    padding: 5px;
    padding-top: 40px;
    cursor: default;
}

@font-face {
    font-family: "Founders Semibold";
    src: local("FoundersGroteskCondensed-Semibold.otf"),
    url("../../../fonts/FoundersGroteskCondensed-Semibold.otf") format("truetype");
}

@font-face {
    font-family: "ABC Medium";
    src: local("ABCFavoritMonoEdu-Medium."),
    url("../../../fonts/ABCFavoritMonoEdu-Medium.otf") format("truetype");
}